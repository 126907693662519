// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-big-data-analysis-startup-js": () => import("./../../../src/pages/big-data-analysis-startup.js" /* webpackChunkName: "component---src-pages-big-data-analysis-startup-js" */),
  "component---src-pages-blog-details-js": () => import("./../../../src/pages/blog-details.js" /* webpackChunkName: "component---src-pages-blog-details-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-case-studies-details-js": () => import("./../../../src/pages/case-studies-details.js" /* webpackChunkName: "component---src-pages-case-studies-details-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-course-details-js": () => import("./../../../src/pages/course-details.js" /* webpackChunkName: "component---src-pages-course-details-js" */),
  "component---src-pages-courses-js": () => import("./../../../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-data-analytics-ai-startup-js": () => import("./../../../src/pages/data-analytics-ai-startup.js" /* webpackChunkName: "component---src-pages-data-analytics-ai-startup-js" */),
  "component---src-pages-data-analytics-ml-consulting-js": () => import("./../../../src/pages/data-analytics-ml-consulting.js" /* webpackChunkName: "component---src-pages-data-analytics-ml-consulting-js" */),
  "component---src-pages-data-science-ml-company-js": () => import("./../../../src/pages/data-science-ml-company.js" /* webpackChunkName: "component---src-pages-data-science-ml-company-js" */),
  "component---src-pages-data-science-online-courses-js": () => import("./../../../src/pages/data-science-online-courses.js" /* webpackChunkName: "component---src-pages-data-science-online-courses-js" */),
  "component---src-pages-digital-marketing-agency-js": () => import("./../../../src/pages/digital-marketing-agency.js" /* webpackChunkName: "component---src-pages-digital-marketing-agency-js" */),
  "component---src-pages-ecommerce-js": () => import("./../../../src/pages/ecommerce.js" /* webpackChunkName: "component---src-pages-ecommerce-js" */),
  "component---src-pages-event-details-js": () => import("./../../../src/pages/event-details.js" /* webpackChunkName: "component---src-pages-event-details-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-history-js": () => import("./../../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-internship-js": () => import("./../../../src/pages/internship.js" /* webpackChunkName: "component---src-pages-internship-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-machine-learning-ai-solutions-js": () => import("./../../../src/pages/machine-learning-ai-solutions.js" /* webpackChunkName: "component---src-pages-machine-learning-ai-solutions-js" */),
  "component---src-pages-membership-levels-js": () => import("./../../../src/pages/membership-levels.js" /* webpackChunkName: "component---src-pages-membership-levels-js" */),
  "component---src-pages-mobile-app-development-js": () => import("./../../../src/pages/mobile-app-development.js" /* webpackChunkName: "component---src-pages-mobile-app-development-js" */),
  "component---src-pages-prodect-design-js": () => import("./../../../src/pages/prodect-design.js" /* webpackChunkName: "component---src-pages-prodect-design-js" */),
  "component---src-pages-profile-authentication-js": () => import("./../../../src/pages/profile-authentication.js" /* webpackChunkName: "component---src-pages-profile-authentication-js" */),
  "component---src-pages-seo-agency-js": () => import("./../../../src/pages/seo-agency.js" /* webpackChunkName: "component---src-pages-seo-agency-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-software-development-js": () => import("./../../../src/pages/software-development.js" /* webpackChunkName: "component---src-pages-software-development-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-web-development-js": () => import("./../../../src/pages/web-development.js" /* webpackChunkName: "component---src-pages-web-development-js" */),
  "component---src-pages-wordpress-js": () => import("./../../../src/pages/wordpress.js" /* webpackChunkName: "component---src-pages-wordpress-js" */)
}

